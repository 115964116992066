@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');
@import './utilities/hamburger.scss';
@import './utilities/footer.scss';

$base-font: 'Manrope', sans-serif;

$base-font-color: #041827;

$contrast-color: #F25A51;
$off-contrast: #F9DFDD;

$nav-height: 125px;

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $base-font;
  scroll-behavior: smooth;
}

html,body {
  width: 100%;
  height: 100%;
}

#root{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.wrapper{
  width: 100%;
  height: 100%;
  min-height: 680px;
  display: flex;
  justify-content: center;
  overflow: auto;
}

section{
  width: 100%;
  height: 100%;
  max-width: 1366px;
  position: relative;
  // display: flex;
  // flex-direction: column;
}

.resp-nav{
  width: 100%;
  margin-top: 125px;
  right: 0;
  top: 0;
  z-index: 999;
  height: calc(100% - 125px);
  position: absolute;
  background-color: $contrast-color;
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 20px;
  transform: translateX(-200%);
  transition: 0.25s ease-in-out;
  a.resp-link{
    font-size: 1.5em;
    color: #fff;
    text-align: left;
    text-decoration: none;
    font-weight: bold;
  }
}

.resp-nav.show{
  transform: translateX(0)
}

.container{
  width: 100%;
  height: calc(100% - $nav-height - 50px);
  max-width: 1366px;
  max-height: 625px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
}

nav{
  padding: 0 50px;
  width: 100%;
  height: $nav-height;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav-logo{
    height: 70px;
    width: auto;
  }

  .nav-links{
    height: 80px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 40px;

    a{
      text-decoration: none;
      text-transform: uppercase;
      color: $base-font-color;
      font-weight: 600;
      font-size: 1.1em;
      position: relative;
      cursor: pointer;
    }

    a:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: -5px;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: $contrast-color;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    a:hover:after { 
      width: 100%; 
      left: 0; 
    }

    a.selected{
      color: $contrast-color;
      font-weight: 700;
    }
  }

}

.hero-box{
  width: 100%;
  height: 100%;
  background-color: #F9DFDD;
  border-radius: 1.5em;
  position: relative;
  max-height: 625px;
  .box-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    border-radius: 0 0 1.5em 1.5em;
  }

  h1{
    position: relative;
    z-index: 2;
    margin: 75px 0 10px 50px;
    font-size: 4em;
    color: $base-font-color;
    span{
      color: $contrast-color;
    }
  }

  p{
    position: relative;
    z-index: 2;
    color: $base-font-color;
    margin: 0 0 20px 55px;
    opacity: .56;
    width: 40%;
  }

  .bg-wrapper{
    position: absolute;
    width: 100%;
    height: 70%;
    object-fit:cover;
    object-position: top center;
    bottom: 0;
    // transform: translateZ(1);
    z-index: 1;
  }

  .bg-wrapper.still{
    z-index: 0;
    // transform: translateZ(0);
  }

  .view-listings{
    position: absolute;
    width: 250px;
    height: 75px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 1em;
    border-radius: 1.5em;
    left: 50%;
    transform: translateX(-50%);
    bottom: -38.5px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: $contrast-color;
    cursor: pointer;
    z-index: 3;
    display: grid;
    place-items: center;
    text-decoration: none;
  }
  .back{
    z-index: -1;
    box-shadow: 0 12px 36px rgba(0,0,0,0.13);
  }
}

.hero-container{
  width: 100%;  
  height: 600px;
  background-color: #000;
  margin-top: 50px;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: MOVE-BG;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  // background-attachment: fixed;
}

.container{
  .title{
    font-size: 2em;
    color: $contrast-color;
    font-weight: 700;
    margin-bottom: 25px;
  }
  .image-wrapper{
    display: flex;
    width: 100%;
    .team-image{
      width: 50%;
      min-width: 314px;
      height: auto;
      border-radius: 1.5em;
      box-shadow: 0 12px 36px rgba(0,0,0,0.13);
    }
    p{
      color: $base-font-color;
      width: 75%; 
      line-height: 1.75em;
      font-weight: 600;
      opacity: .75;
    }
  }

}

.hero-container.mortgage{
  padding: 20px 10px;
  .hero-title{
    color: $contrast-color;
    font-weight: bold;
    font-size: 5em;
    // text-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    // border-radius: 20px;
  }
}

.loan-programs{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  .loan-program{
    width: fit-content;
    min-width: 400px;
    padding: 10px;
    margin-left: 10px ;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 50px;
    color: $contrast-color;
    font-size: 1.5em;
    transition: 0.25s ease-in-out;
    &:hover{
      transform: scale(1.05);
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }

    .loan-name{
      font-size: 18px;
      color: #041827;
      padding: 0 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.25);
    }
  }
}

@media screen and (max-width: 820px) {

  .container.loans{
    height: fit-content;
    max-height: unset;
  }

  .loan-programs{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    margin-left: 0px;
    gap: 20px;
    margin-bottom: 50px;
    height: 100%;
    .loan-program{
      width: 100%;
      min-width: unset;
      padding: 15px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      height: fit-content;
      min-height: 50px;
      color: $contrast-color;
      font-size: 1.5em;
      transition: 0.25s ease-in-out;
      &:hover{
        transform: scale(1.05);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      }
  
      .loan-name{
        font-size: 18px;
        color: #041827;
        padding: 0 10px;
        border-left: 1px solid rgba(0, 0, 0, 0.25);
      }
    }
  }
  
}

.container.team{
  // margin-top: 25px;
  padding-top: 50px;
  height: fit-content;
  // background-color: $off-contrast;
  // border-radius: 1.5em;
}
.container.team.mort{
  margin-bottom: 0px !important;
}

.container.roster-title{
  height: fit-content;
  margin-bottom: 0px;
}

.container.agents-title{
  height: fit-content;
  p{
    color: $base-font-color;
    width: 75%; 
    line-height: 1em;
    font-weight: 600;
    opacity: .75;
    margin: 0  0 50px 0;
  }
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 50px;
    height: 50px;
    width: 200px;
    background-color: $contrast-color;
    border: none;
    outline: none;
    border-radius: 50px;
    font-size: 1.2em;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }
}

.container.roster.secure{
  justify-content: unset;
  gap: 20px;
}

.container.roster{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  max-height: unset;
  .agent{
    margin: 150px 5px 20px 5px;
    width: 250px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    padding: 1rem 1rem 2.5rem 1rem;
    background-color: #fff;
    transition: 0.25s ease-in;
    position: relative;
    // overflow: hidden;
    border-radius: .5em;
    position: relative;
    box-shadow: 0 12px 36px rgba(0,0,0,0.13);
    img{
      width: 80%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      position: absolute;
      top: -50%;
      left: 50%;
      transform: translateX(-50%);
    }
    .name{
      font-size: 1.2em;
      font-weight: 700;
      color: $contrast-color;
    }
    .post{
      font-size: .9em;
    }

    .agent-socials{
      position: absolute;
      width: 90%;
      height: 50px;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-evenly;
      .social-btn{
        height: 100%;
        aspect-ratio: 1/1;
        background-color: #fff;
        box-shadow: 0 12px 36px rgba(0,0,0,0.15);
        border-radius: 50%;
        display: grid;
        place-items: center;
        cursor: pointer;
      }
    }
  }

}

.container.loans{
  height: fit-content !important;
}

.image-wrapper.props{
  flex-direction: column;
}


.hero-container.property{
  padding: 20px 50px;
  background-color: transparent;
  height: fit-content;
  min-height: unset;

  .hero-title{
    color: $contrast-color;
    font-weight: bold;
    font-size: 2em;
  }

  .header{
    width: 100%;
    margin-top: 50px;
    height: auto;
    max-height: 600px;
    object-fit: cover;
  }
}

.container.joinus{
  padding: 50px;
  border-radius: 1.5em;
  margin-bottom: 50px;
  max-height: unset;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: $off-contrast;

  form{
    select{
      width: 100%;
      margin-top: 2em;
      padding: 1em;
      font-size: 1em;
      border-radius: 8px;
      outline-width: 0px;
      border: none;
      background-color: #f5f5f5;
    }
    #input-submit{
      width: 100%;
    }
  }
}

@media screen and (max-width: 1366px) {
  .container.joinus{
    border-radius: 0px;
  }
}

.container.about{
  padding-top: 50px;
  margin-bottom: 50px;
  max-height: unset;
  height: fit-content;
  display: flex;
  flex-direction: column;
  .title{
    margin-bottom: 5px;
  }

  .catchphrase{
    margin-bottom: 40px;
    font-size: 2.25em;
    color: #0d1017d3;
    font-weight: 500;
    .highlight{
      color: $contrast-color;
      font-weight: 700;
    }
  }

  .aboutussection{
    flex: 1;
    width: 100%;
    display: flex;
    img{
      width: 40%;
      height: 100%;
      object-fit: cover;
      border-radius: 1em;
    }
    p{
      flex: 1;
      padding: 10px 40px 20px 40px;
      white-space: now;
      color: $base-font-color;
      font-size: 1.1em;
      span{
        color: $contrast-color;
        font-weight: bold;
      }
    }
  }



  @media screen and (max-width: 820px) {
    .aboutussection{
      flex-direction: column;
      gap: 20px;
      img{
        width: 100%;
        height: 400px;
      }
      p{
        padding: 10px 20px 20px 20px;
      }
    }
  }
}


@media screen and (max-width: 960px) {

  .web-wrapper.open-nav{
    transform: translateX(-300px);
  }

  // .resp-nav{
  //   z-index: 999;
  //   position: fixed;
  //   width: 300px;
  //   height: 100%;
  //   right: 0;
  //   background-color: $contrast-color;
  // }

  .nav-links a{
      display: none;
  }
}

@media screen and (max-width: 820px) {


  .hero-container.mortgage{
    padding: 25px;
    img{
      width: 100%;
      height: auto;
    }
    .hero-title{
      color: $contrast-color;
      font-weight: bold;
      font-size: 3em;
    }
  }

  .hero.container{
    padding: 0;
  }

  .hero-box{
    border-radius: 0;
    h1{
      font-size: 3em;
      margin: 1em 1em 0 1em;
    }
    p{
      margin: 20px 50px 0 50px;
      width: 75%;
    }
    .box-wrapper{
      border-radius: 0;
      .bg-wrapper{
        margin-bottom: -50px;
        object-position: center right;
      }
    }
  }
}

.container.contact{
  max-height: unset;
  height: fit-content;
  margin-bottom: 50px;
  .title{
    margin-top: 50px;
  }
}

@media screen and (max-height: 720px) {

  .container-spacer{
    width: 100%;
    height: 50px;
  }

}

@keyframes MOVE-BG {
  from {
    background-position: 0 center;
  }
  to { 
    background-position: 100% center;
  }
}