@import "https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap";
.toggle {
  cursor: pointer;
  display: none;
  position: relative;
}

.toggle input {
  display: none;
}

.toggle input + div {
  width: 20px;
  height: 14px;
  position: relative;
}

.toggle input + div div {
  transition: transform .5s;
  position: absolute;
  inset: 0;
}

.toggle input + div div span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

.toggle input + div div span:first-child, .toggle input + div div span:first-child:before, .toggle input + div div span:first-child:after {
  top: 0;
}

.toggle input + div div span:last-child, .toggle input + div div span:last-child:before, .toggle input + div div span:last-child:after {
  bottom: 0;
}

.toggle input + div div span:before, .toggle input + div div span:after {
  content: "";
  width: 47%;
  height: 2px;
  -webkit-backface-visibility: hidden;
  background: #f25a51;
  border-radius: 1px;
  transition: transform .5s, border-radius .3s, background .4s;
  display: block;
  position: absolute;
}

.toggle input + div div span:before {
  transform-origin: 0;
  left: 0;
  transform: translate(1px)scaleX(1.1);
}

.toggle input + div div span:after {
  transform-origin: 100%;
  right: 0;
  transform: translate(-1px)scaleX(1.1);
}

.toggle input + div svg {
  fill: none;
  stroke: #f25a51;
  stroke-width: 2px;
  width: 44px;
  height: 44px;
  stroke-linecap: round;
  stroke-dasharray: 0 82.801 8 82.801;
  stroke-dashoffset: 82.801px;
  transform-origin: 50%;
  -webkit-backface-visibility: hidden;
  margin: -22px 0 0 -22px;
  transition: stroke-dashoffset .5s, stroke-dasharray .6s, transform .5s, stroke .4s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(1);
}

.toggle input + div svg:nth-child(3) {
  transform: rotate(180deg)scale(1);
}

.toggle input:checked + div div {
  transform: rotate(90deg);
}

.toggle input:checked + div div span:before, .toggle input:checked + div div span:after {
  background: #f25a51;
}

.toggle input:checked + div div span:first-child:before {
  transform: rotate(45deg)translate(2.2px, -3px)scaleX(1.05);
}

.toggle input:checked + div div span:first-child:after {
  transform: rotate(-45deg)translate(-2.2px, -3px)scaleX(1.05);
}

.toggle input:checked + div div span:last-child:before {
  transform: rotate(-45deg)translate(2.2px, 3px)scaleX(1.05);
}

.toggle input:checked + div div span:last-child:after {
  transform: rotate(45deg)translate(-2.2px, 3px)scaleX(1.05);
}

.toggle input:checked + div svg {
  stroke-dashoffset: 62px;
  stroke-dasharray: 0 82.801 62 82.801;
  stroke: #f25a51;
  transform: rotate(90deg);
}

.toggle input:checked + div svg:nth-child(3) {
  transform: rotate(270deg);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 960px) {
  .toggle {
    display: block;
  }
}

footer {
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  transition: all .5s ease-out;
  display: flex;
}

footer.visible {
  opacity: 1;
  transform: translateY(0);
}

footer .container {
  max-height: unset;
  max-width: 1366px;
  height: fit-content;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px 50px 0;
  display: flex;
}

.footer-text {
  width: 50%;
  flex-direction: column;
  display: flex;
}

.footer-name {
  align-items: center;
  gap: 5px;
  display: flex;
}

.footer-name img {
  width: 100px;
}

.footer-para {
  color: #041827;
  margin-top: 20px;
  font-size: 1.5em;
  line-height: 140%;
}

.footer-email, .footer-rights {
  color: #041827;
  opacity: .75;
  margin-top: 10px;
  font-size: 1em;
  font-weight: 300;
}

.footer-email {
  font-size: .95em;
}

.footer-email.addresses {
  margin: 20px 0;
  display: flex;
}

.footer-email.addresses .location {
  width: 40%;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.footer-email.addresses .location span {
  opacity: 1;
  font-weight: 500;
}

.footer-email.addresses .location .phone {
  font-weight: 600;
}

.footer-email.addresses .location .loc-title {
  font-size: 1.2em;
  font-weight: 700;
}

@media screen and (max-width: 820px) {
  .footer-email.addresses {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 40px;
  }

  .footer-email.addresses .location {
    width: 90%;
  }
}

.footer-rights {
  margin-top: 30px;
}

.footer-links {
  width: 50%;
  display: flex;
}

.footer-navigation, .footer-socials {
  width: 50%;
  flex-direction: column;
  padding-top: 90px;
  display: flex;
}

.footer-section-title {
  color: #041827;
  opacity: .5;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: .8em;
  font-weight: 600;
}

.footer-section-link {
  color: #041827;
  margin-top: 20px;
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
}

.footer-section-link.social {
  font-size: 2em;
}

.footer-section-link.selected {
  color: #f25a51;
}

@media screen and (max-width: 820px) {
  footer {
    height: fit-content;
  }

  footer .container {
    flex-direction: column;
    padding-bottom: 50px;
  }

  .footer-links {
    width: 100%;
    justify-content: center;
  }

  .footer-text {
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .footer-para {
    width: 80%;
  }

  .footer-navigation, .footer-socials {
    align-items: center;
  }

  .footer-navigation .footer-section-link, .footer-socials .footer-section-link {
    text-align: center;
  }
}

form {
  text-align: center;
}

form input, form textarea {
  width: 100%;
  resize: none;
  background-color: #f5f5f5;
  border: 0;
  border-radius: 8px;
  outline: 0;
  margin-top: 2em;
  padding: 1em;
  font-size: 1em;
  display: block;
}

form input:focus, form textarea:focus {
  box-shadow: 0 0 2px #e74c3c !important;
}

form #input-submit {
  color: #fff;
  cursor: pointer;
  background: #f25a51;
}

form #input-submit:hover {
  box-shadow: 0 1px 1px 1px #aaa9;
}

form textarea {
  height: 126px;
}

.half {
  float: left;
  width: 48%;
  margin-bottom: 1em;
}

.right {
  width: 50%;
}

.left {
  margin-right: 2%;
}

@media (max-width: 480px) {
  .half {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }
}

.cf:before, .cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: Manrope, sans-serif;
}

html, body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 680px;
  justify-content: center;
  display: flex;
  overflow: auto;
}

section {
  width: 100%;
  height: 100%;
  max-width: 1366px;
  position: relative;
}

.resp-nav {
  width: 100%;
  z-index: 999;
  height: calc(100% - 125px);
  background-color: #f25a51;
  flex-direction: column;
  gap: 20px;
  margin-top: 125px;
  padding: 50px;
  transition: all .25s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-200%);
}

.resp-nav a.resp-link {
  color: #fff;
  text-align: left;
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
}

.resp-nav.show {
  transform: translateX(0);
}

.container {
  width: 100%;
  height: calc(100% - 175px);
  max-width: 1366px;
  max-height: 625px;
  flex-direction: column;
  margin-bottom: 75px;
  padding: 0 50px;
  display: flex;
}

nav {
  width: 100%;
  height: 125px;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  display: flex;
}

nav .nav-logo {
  height: 70px;
  width: auto;
}

nav .nav-links {
  height: 80px;
  width: fit-content;
  align-items: center;
  gap: 40px;
  display: flex;
}

nav .nav-links a {
  text-transform: uppercase;
  color: #041827;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

nav .nav-links a:after {
  content: "";
  height: 2px;
  width: 0;
  background: #f25a51;
  transition: width .3s, left .3s;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 50%;
}

nav .nav-links a:hover:after {
  width: 100%;
  left: 0;
}

nav .nav-links a.selected {
  color: #f25a51;
  font-weight: 700;
}

.hero-box {
  width: 100%;
  height: 100%;
  max-height: 625px;
  background-color: #f9dfdd;
  border-radius: 1.5em;
  position: relative;
}

.hero-box .box-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0 0 1.5em 1.5em;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.hero-box h1 {
  z-index: 2;
  color: #041827;
  margin: 75px 0 10px 50px;
  font-size: 4em;
  position: relative;
}

.hero-box h1 span {
  color: #f25a51;
}

.hero-box p {
  z-index: 2;
  color: #041827;
  opacity: .56;
  width: 40%;
  margin: 0 0 20px 55px;
  position: relative;
}

.hero-box .bg-wrapper {
  width: 100%;
  height: 70%;
  object-fit: cover;
  object-position: top center;
  z-index: 1;
  position: absolute;
  bottom: 0;
}

.hero-box .bg-wrapper.still {
  z-index: 0;
}

.hero-box .view-listings {
  width: 250px;
  height: 75px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  z-index: 3;
  background-color: #f25a51;
  border: none;
  border-radius: 1.5em;
  outline: none;
  place-items: center;
  font-size: 1em;
  font-weight: 700;
  text-decoration: none;
  display: grid;
  position: absolute;
  bottom: -38.5px;
  left: 50%;
  transform: translateX(-50%);
}

.hero-box .back {
  z-index: -1;
  box-shadow: 0 12px 36px #00000021;
}

.hero-container {
  width: 100%;
  height: 600px;
  background-color: #000;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  animation-name: MOVE-BG;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.container .title {
  color: #f25a51;
  margin-bottom: 25px;
  font-size: 2em;
  font-weight: 700;
}

.container .image-wrapper {
  width: 100%;
  display: flex;
}

.container .image-wrapper .team-image {
  width: 50%;
  min-width: 314px;
  height: auto;
  border-radius: 1.5em;
  box-shadow: 0 12px 36px #00000021;
}

.container .image-wrapper p {
  color: #041827;
  width: 75%;
  opacity: .75;
  font-weight: 600;
  line-height: 1.75em;
}

.hero-container.mortgage {
  padding: 20px 10px;
}

.hero-container.mortgage .hero-title {
  color: #f25a51;
  background-color: #0003;
  padding: 10px 20px;
  font-size: 5em;
  font-weight: bold;
}

.loan-programs {
  width: 100%;
  flex-direction: column;
  margin-top: 25px;
  display: flex;
}

.loan-programs .loan-program {
  width: fit-content;
  min-width: 400px;
  height: 50px;
  color: #f25a51;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  padding: 10px;
  font-size: 1.5em;
  transition: all .25s ease-in-out;
  display: flex;
}

.loan-programs .loan-program:hover {
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  transform: scale(1.05);
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.loan-programs .loan-program .loan-name {
  color: #041827;
  border-left: 1px solid #00000040;
  padding: 0 10px;
  font-size: 18px;
}

@media screen and (max-width: 820px) {
  .container.loans {
    height: fit-content;
    max-height: unset;
  }

  .loan-programs {
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 20px;
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: 0;
    display: flex;
  }

  .loan-programs .loan-program {
    width: 100%;
    min-width: unset;
    height: fit-content;
    min-height: 50px;
    color: #f25a51;
    align-items: center;
    gap: 10px;
    padding: 15px 10px;
    font-size: 1.5em;
    transition: all .25s ease-in-out;
    display: flex;
  }

  .loan-programs .loan-program:hover {
    transition: all .3s cubic-bezier(.25, .8, .25, 1);
    transform: scale(1.05);
    box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  }

  .loan-programs .loan-program .loan-name {
    color: #041827;
    border-left: 1px solid #00000040;
    padding: 0 10px;
    font-size: 18px;
  }
}

.container.team {
  height: fit-content;
  padding-top: 50px;
}

.container.team.mort {
  margin-bottom: 0 !important;
}

.container.roster-title {
  height: fit-content;
  margin-bottom: 0;
}

.container.agents-title {
  height: fit-content;
}

.container.agents-title p {
  color: #041827;
  width: 75%;
  opacity: .75;
  margin: 0 0 50px;
  font-weight: 600;
  line-height: 1em;
}

.container.agents-title button {
  height: 50px;
  width: 200px;
  color: #fff;
  cursor: pointer;
  background-color: #f25a51;
  border: none;
  border-radius: 50px;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
}

.container.roster.secure {
  justify-content: unset;
  gap: 20px;
}

.container.roster {
  height: fit-content;
  max-height: unset;
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.container.roster .agent {
  width: 250px;
  height: 200px;
  text-align: center;
  background-color: #fff;
  border-radius: .5em;
  flex-direction: column;
  justify-content: flex-end;
  margin: 150px 5px 20px;
  padding: 1rem 1rem 2.5rem;
  transition: all .25s ease-in;
  display: flex;
  position: relative;
  box-shadow: 0 12px 36px #00000021;
}

.container.roster .agent img {
  width: 80%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
}

.container.roster .agent .name {
  color: #f25a51;
  font-size: 1.2em;
  font-weight: 700;
}

.container.roster .agent .post {
  font-size: .9em;
}

.container.roster .agent .agent-socials {
  width: 90%;
  height: 50px;
  justify-content: space-evenly;
  display: flex;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.container.roster .agent .agent-socials .social-btn {
  height: 100%;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  place-items: center;
  display: grid;
  box-shadow: 0 12px 36px #00000026;
}

.container.loans {
  height: fit-content !important;
}

.image-wrapper.props {
  flex-direction: column;
}

.hero-container.property {
  height: fit-content;
  min-height: unset;
  background-color: #0000;
  padding: 20px 50px;
}

.hero-container.property .hero-title {
  color: #f25a51;
  font-size: 2em;
  font-weight: bold;
}

.hero-container.property .header {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: cover;
  margin-top: 50px;
}

.container.joinus {
  max-height: unset;
  height: fit-content;
  background-color: #f9dfdd;
  border-radius: 1.5em;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 50px;
  display: flex;
}

.container.joinus form select {
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
  outline-width: 0;
  margin-top: 2em;
  padding: 1em;
  font-size: 1em;
}

.container.joinus form #input-submit {
  width: 100%;
}

@media screen and (max-width: 1366px) {
  .container.joinus {
    border-radius: 0;
  }
}

.container.about {
  max-height: unset;
  height: fit-content;
  flex-direction: column;
  margin-bottom: 50px;
  padding-top: 50px;
  display: flex;
}

.container.about .title {
  margin-bottom: 5px;
}

.container.about .catchphrase {
  color: #0d1017d3;
  margin-bottom: 40px;
  font-size: 2.25em;
  font-weight: 500;
}

.container.about .catchphrase .highlight {
  color: #f25a51;
  font-weight: 700;
}

.container.about .aboutussection {
  width: 100%;
  flex: 1;
  display: flex;
}

.container.about .aboutussection img {
  width: 40%;
  height: 100%;
  object-fit: cover;
  border-radius: 1em;
}

.container.about .aboutussection p {
  white-space: now;
  color: #041827;
  flex: 1;
  padding: 10px 40px 20px;
  font-size: 1.1em;
}

.container.about .aboutussection p span {
  color: #f25a51;
  font-weight: bold;
}

@media screen and (max-width: 820px) {
  .container.about .aboutussection {
    flex-direction: column;
    gap: 20px;
  }

  .container.about .aboutussection img {
    width: 100%;
    height: 400px;
  }

  .container.about .aboutussection p {
    padding: 10px 20px 20px;
  }
}

@media screen and (max-width: 960px) {
  .web-wrapper.open-nav {
    transform: translateX(-300px);
  }

  .nav-links a {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .hero-container.mortgage {
    padding: 25px;
  }

  .hero-container.mortgage img {
    width: 100%;
    height: auto;
  }

  .hero-container.mortgage .hero-title {
    color: #f25a51;
    font-size: 3em;
    font-weight: bold;
  }

  .hero.container {
    padding: 0;
  }

  .hero-box {
    border-radius: 0;
  }

  .hero-box h1 {
    margin: 1em 1em 0;
    font-size: 3em;
  }

  .hero-box p {
    width: 75%;
    margin: 20px 50px 0;
  }

  .hero-box .box-wrapper {
    border-radius: 0;
  }

  .hero-box .box-wrapper .bg-wrapper {
    object-position: center right;
    margin-bottom: -50px;
  }
}

.container.contact {
  max-height: unset;
  height: fit-content;
  margin-bottom: 50px;
}

.container.contact .title {
  margin-top: 50px;
}

@media screen and (max-height: 720px) {
  .container-spacer {
    width: 100%;
    height: 50px;
  }
}

@keyframes MOVE-BG {
  from {
    background-position: 0;
  }

  to {
    background-position: 100%;
  }
}

/*# sourceMappingURL=index.2f7bacb6.css.map */
