footer{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    padding-bottom: 50px;
    margin-bottom: 50px;
    // opacity: 0;
    /* transform: ; */
    transition: 0.5s ease-out;
    // overflow: hidden;
}

footer.visible{
    opacity: 1;
    transform: translateY(0px)
}

footer .container{
    max-height: unset;
    max-width: 1366px;
    display: flex;
    height: fit-content;
    padding: 0 50px;
    margin-top: 50px;
    padding-top: 50px;
    flex-direction: row;
    margin-bottom: 50px;
}

.footer-text{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.footer-name{
    display: flex;
    align-items: center;
    gap: 5px;
}

.footer-name img{
    width: 100px;
    // object-fit: contain;
    // aspect-ratio: 1/1;
}

.footer-para{
    margin-top: 20px;
    font-size: 1.5em;
    line-height: 140%;
    color: #041827;
}

.footer-email, .footer-rights{
    font-size: 1em;
    color: #041827;
    font-weight: 300;
    margin-top: 10px;
    opacity: 0.75;
}

.footer-email{
    font-size: .95em;
}

.footer-email.addresses{
    margin: 20px 0;
    display: flex;
    .location{
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      span{
        opacity: 1;
        font-weight: 500;
      }
      .phone{
        font-weight: 600;
      }

      .loc-title{
        font-size: 1.2em;
        font-weight: 700;
      }
    }
}

@media screen and (max-width: 820px) {
  .footer-email.addresses{
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 40px;
    .location{
      width: 90%;
    }
  }
}

.footer-rights{
    margin-top: 30px;
}


.footer-links{
    width: 50%;
    display: flex;
}

.footer-navigation, .footer-socials{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-top: 90px;
    // align-items: center;
}

.footer-section-title{
    font-size: 0.8em;
    font-weight: 600;
    color: #041827;
    opacity: 0.5;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.footer-section-link{
    color: #041827;
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;
    margin-top: 20px;
}

.footer-section-link.social{
  font-size: 2em;
}

.footer-section-link.selected{
    color: #F25A51;
}

@media screen and (max-width: 820px) {

    footer{
        height: fit-content;
    }

    footer .container{
        flex-direction: column;
        padding-bottom: 50px;
    }

    .footer-links{
        width: 100%;
        justify-content: center;
    }

    .footer-text{
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .footer-para{
        width: 80%;
    }

    .footer-navigation, .footer-socials{
        align-items: center;
        .footer-section-link{
            text-align: center;
        }
    }
}


form {
    text-align: center;
    // margin: 20px auto;
  }
  form input, form textarea {
    font-size: 1em;
    border: 0;
    outline: 0;
    padding: 1em;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    display: block;
    width: 100%;
    background-color:rgb(245, 245, 245);
    margin-top: 2em;
    // -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    // -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    resize: none;
  }
  form input:focus, form textarea:focus {
    -moz-box-shadow: 0 0px 2px #e74c3c !important;
    -webkit-box-shadow: 0 0px 2px #e74c3c !important;
    box-shadow: 0 0px 2px #e74c3c !important;
  }
  form #input-submit {
    color: white;
    background: #F25A51;
    cursor: pointer;
  }
  form #input-submit:hover {
    -moz-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
    -webkit-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
    box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  }
  form textarea {
    height: 126px;
  }
  
  .half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
  }
  
  .right {
    width: 50%;
  }
  
  .left {
    margin-right: 2%;
  }
  
  @media (max-width: 480px) {
    .half {
      width: 100%;
      float: none;
      margin-bottom: 0;
    }
  }
  /* Clearfix */
  .cf:before,
  .cf:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  
  .cf:after {
    clear: both;
  }
  